<script>
  /**
   * Revenue Analytics component
   */
  export default {
    props: {
      Data: Object
    },
    mounted() {

      this.GetVisualTable()
    },
    data() {
      return {
        series: [
          {
            name: "活跃走势",
            data: [10, 41, 35, 51, 49, 62, 69]
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          title: {
            text: "次",
            align: "left"
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: [
            ]
          }
        },
        DateSwitch: 7,
        statData: [
          {
            title: "昨天注册量",
            icon: " ri-user-fill",
            value: "101",
            subvalue: " 2.4% "
          },
          {
            title: "今天注册量",
            icon: " ri-user-fill",
            value: "215",
            subvalue: " 2.4% "
          },
          {
            title: "总注册量",
            icon: " ri-user-fill",
            value: "1024",
            subvalue: " 2.4% "
          }
        ],
        DataKey: "12312",
        TimeData:"",
        SwitchDemo:"active"
      };
    },
    methods: {
      GetVisualTable() {
        _API_.VisualTable({
          register: this.DateSwitch
        }).then((res) => {
          if (res.code == 200) {
            this.TimeData = {
              active_day_7:res.active_day_7,
              active_day_30:res.active_day_30,
              register_day_7:res.register_day_7,
              register_day_30:res.register_day_30,
              
            }
            
            let SwitchData = this.SwitchDemo == 'active' ? 'active_count_dict' : 'register_count_dict'
            console.log(SwitchData)
            let ResData = res[SwitchData]
            console.log(ResData)
            this.chartOptions.xaxis = {
              categories: Object.keys(ResData).map((item) => {
                return item.substring(item.length - 5)
              })
            }
        
            this.series[0].data = Object.values(ResData)

            let RegisterList = Object.values(res.register_count_dict)
            this.statData[0].value = RegisterList[RegisterList.length-2]
            this.statData[1].value = RegisterList[RegisterList.length-1]
            this.statData[2].value = res.count

            this.$refs.apexchart.updateOptions({
            xaxis: {
              categories: Object.keys(ResData).map((item) => {
                return item.substring(item.length - 5)
              })
            }
          }, true)
          this.$refs.apexchart.updateSeries([{ data: Object.values(ResData) }])
          this.$forceUpdate();
          }
        
         
        })
      },
      DateSwitchFun(day) {
        this.DateSwitch = day
        this.GetVisualTable()
      },
      SwitchVisual(Item)
      {
        this.SwitchDemo=Item
        this.series[0].name = Item == 'active' ? '活跃走势' : '注册走势'
        this.GetVisualTable()
      }
    }
  };
</script>

<template>
  <div v-if="this.chartOptions.xaxis.categories.length > 0" :key="DataKey">
    <div class="row">
      <div class="col-md-4" v-for="(data, index) in statData" :key="index">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
                <h4 class="mb-0">{{data.value}}</h4>
              </div>
              <div class="text-primary">
                <i :class="`${data.icon} font-size-24`"></i>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card">

      <div class="card-body">
        <div class="float-right d-none d-md-inline-block">
          <div class="btn-group mb-2">
            <button type="button" @click="DateSwitchFun(7)" :class="DateSwitch == 7 ? 'active' : '' "
              class="btn btn-sm btn-light ">周</button>
            <button type="button" @click="DateSwitchFun(30)" :class="DateSwitch == 30 ? 'active' : '' "
              class="btn btn-sm btn-light">月</button>
          </div>
        </div>
        <h4 class="card-title mb-4">
          <span @click="SwitchVisual('active')" class="demo">用户活跃量</span>
          <span style="margin: 0 10px;">/</span>
          <span @click="SwitchVisual('reg')" class="demo">用户注册量</span>
        </h4>
        <div>
          <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
          <apexchart ref="apexchart" class="apex-charts" height="280" dir="ltr" :series="series"
            :options="chartOptions"></apexchart>
        </div>
      </div>

      <div class="card-body border-top text-center">
        <div class="row">
          <div class="col-sm-4">
            <div class="mt-4 mt-sm-0">
              <p class="mb-2 text-muted text-truncate">
                <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> 日
              </p>
              <div class="d-inline-flex">

                <h5 class="mb-0">{{series[0].data[series[0].data.length-1]}}</h5>
     
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="mt-4 mt-sm-0">
              <p class="mb-2 text-muted text-truncate">
                <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> 周
              </p>
              <div class="d-inline-flex">
                <h5 class="mb-0">{{TimeData.active_day_7}}</h5>
                <!-- <div class="text-success">
                  <i class="mdi mdi-menu-up font-size-14"></i>2.1 %
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="mt-4 mt-sm-0">
              <p class="mb-2 text-muted text-truncate">
                <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>月
              </p>
              <div class="d-inline-flex">
                <h5 class="mb-0">{{TimeData.active_day_30}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<style>
  .demo{
    cursor:pointer;
  }
  .demo:hover{
    color: rgb(163, 163, 163);
  }
</style>