
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <!-- 左上两图表 -->
        <RevenueAnalytics v-show="Demo=='user'" />
        <DealVisualTable v-show="Demo=='deal'" />
      </div>

    </div>

    <div class="row">
      <div class="col-xl-12">
        <!-- 左上两图表 -->
        <div class="card-body border-top text-center">
          <div class="row">
            <div class="col-sm-12">
              <div class="mt-4 mt-sm-0" style="padding:25px 0;">
                <el-button type="primary" @click="SwitchVisual('user')">用户可视化图表</el-button>
                <el-button type="success" @click="SwitchVisual('deal')">商户可视化图表</el-button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>


    <!-- <div class="row">
      <div class="col-lg-4">
        <Sources />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RevenueLocation />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <Chat />
      </div>
      <div class="col-lg-8">
        <Transaction />
      </div>
    </div> -->
  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";

  import Stat from "./widget";
  import RevenueAnalytics from "./revenue";
  import DealVisualTable from "./revenue2";
  import SalesAnalytics from "./sales-analytics";
  import EarningReport from "./earning";
  import Sources from "./sources";
  import RecentActivity from "./recent-activity";
  import RevenueLocation from "./revenue-location";
  import Chat from "./chat";
  import Transaction from './transaction';

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      Stat,
      RevenueAnalytics,
      SalesAnalytics,
      EarningReport,
      Sources,
      RecentActivity,
      RevenueLocation,
      DealVisualTable,
      Chat,
      Transaction
    },
    data() {
      return {
        title: "可视化图表",
        items: [
          {
            text: "商票易后台管理系统"
          },
          {
            text: "可视化图表",
            active: true
          }
        ],
        Demo:"user"
      };
    },
    methods: {
      SwitchVisual(Item){
        // this.$set(this, 'Visual', Item)
        this.Demo = Item
      }
    }
  };
</script>
